/* 
These animations are the fading effects used in interface.js for emitting and dismissing toasts
*/

@keyframes fadeIn {
  from,
  to {
    animation-timing-function: ease;
  }

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}
